import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ContactForm from 'components/contact-form'
import { Helmet } from 'react-helmet'
import OGImage from '../../../content/images/logo.png'
import ReferralForm from '../../../static/Meridian Endo Referral Form.pdf'

interface Props {
  location: Location
}

const ReferralFormPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid
  const section1_img = data.section1_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>Referring Doctors Indianapolis IN</title>
        <meta name="description" content="Indianapolis IN Endodontist Dr. Broady 317-846-4980" />
        <link rel="canonical" href="https://meridianendoindy.com/referring-doctors/referral-form/" />
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Referring Doctors Indianapolis IN" />
        <meta name="og:description" content="Indianapolis IN Endodontist Dr. Broady 317-846-4980" />
        <meta property="og:url" content="https://meridianendoindy.com/referring-doctors/referral-form/" />
        <meta property="og:image" content={OGImage} />
        <meta property="og:image:width" content="279" />
        <meta property="og:image:height" content="65" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Referring Doctors Indianapolis IN" />
        <meta name="twitter:description" content="Indianapolis IN Endodontist Dr. Broady 317-846-4980" />
        <meta name="twitter:url" content="https://meridianendoindy.com/referring-doctors/referral-form/" />
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279" />
        <meta property="og:image:height" content="65" />
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="referral-form-section-bg hero-bg"
        fluid={hero_background}
        backgroundColor={`#ffffff`}
        alt="Endodontics pc"
      >
        <div id="referral-form-hero-section">
          <Container>
            <div className="col-lg-6 left-content">
              <h1 className="primary-text hero-heading">
                Referring Doctors
              </h1>
              <p className="hero-text mb-3">
                Our office is a referral based practice. Our success depends on the confidence you show in us by referring your patients for their specialty endodontic care.
              </p>
              <Link to="/contact-us/contact-information-office-map"><button className="hero-section-btn"> Schedule a Consultation</button></Link>
            </div>
            <div className="col-lg-6">
              <Img fluid={hero_img} className="hero-img" alt="lady wearing black long sleeves holding orange folder"/>
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <section id="faded-bg"></section>
      <section id="referral-form-section-1">
        <Container>
          <div className="col-lg-12 box-container">
            <div className="row">
              <div className="col-lg-3">
                <Img fluid={section1_img} className="pdf-icon"/>
              </div>
              <div className="col-lg-9">
                <strong><p>Our office is a referral based practice. Our success depends on the confidence you show in us by referring your patients for their specialty Endodontic Care. Please call our office at <a href="tel:3178464980">317-846-4980</a> to schedule an appointment, request referral materials, ask questions or discuss your patient’s particular needs.</p></strong>
                <p>You may refer patients to our office by downloading our Referral Form.</p>
                {/*<a href="https://meridianendoindy.com/files/2016/11/CB316010-6316-4803-B163-D4D1B06677C3.pdf" target="_blank">
                  <button className="hero-section-btn"> Download Referral Form </button>
                </a>*/}
                <a href={ReferralForm} download >
                  <button className="hero-section-btn"> Download Referral Form </button>
                </a>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section id="referral-form-section-2">
        <ContactForm />
      </section>
    </Layout>
  )
}

export default ReferralFormPage

export const query = graphql`
  query ReferralFormPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "introduction-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "referral-form-hero-img" }) {
      childImageSharp {
        fluid(maxWidth: 649, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section1_img: file(name: { eq: "pdf-icon" }) {
      childImageSharp {
        fluid(maxWidth: 347, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
