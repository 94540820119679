import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Img, { FixedObject } from 'gatsby-image'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby'
import BigTooth from '/content/images/big-tooth.png'
import GreenDots from '/content/images/contact-dots.png'
import Waves from '/content/images/waves.png'

const ContactForm = ({ action, ...props }) => (
  <div id="contact-form">
    <Container>
      <div className="col-lg-4 text-center">
        <img src={BigTooth} className="big-tooth-img" alt="big tooth with magnifying glass"/>
      </div>
      <div className="col-lg-8 form-right">
        <img src={GreenDots} className="contact-dots-img"/>
        <h4 className="primary-text">Contact Us Today</h4>
        <p>If you are an existing patient, this contact form should not be utilized for communicating private health information.</p>
        <form className="main-form" action="https://usebasin.com/f/f5c1f073ca48" method="POST">
          <div className="row">
            <div className="col-lg-6 pr-0">
              <input type="text" placeholder="Your Name*" name="name" required/>
            </div>
            <div className="col-lg-6">
              <input type="tel" placeholder="Your Phone Number*" name="telephone" required/>
            </div>
          </div>
          <div className="col-lg-12">
            <input type="email" placeholder="Your Email Address*" name="email" required/>
          </div>
          <div className="col-lg-12">
            <textarea placeholder="Comments/Questions*" name="comments" required/>
          </div>
          <button type="submit" name="submit"> Submit </button>
        </form>
      </div>
      <img src={Waves} className="waves-img"/>
    </Container>
  </div>
)
export default ContactForm
